import { Component, NgZone } from '@angular/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { LoggerService } from '@app/core/logger.service';
import {
  CallState,
  ErrorMessage,
  ShowErrorOptions,
  VideoContainer,
  VideoState,
} from '@app/core/models/video-container';
import { INTERNAL_USER_DOM_ID, PATIENT_DOM_ID } from '@app/core/models/video-provider';
import { OpenTokService } from '@app/core/open-tok/open-tok.service';
import { AlertType } from '@app/ui/alert/alert.component';

@Component({
  selector: 'om-open-tok',
  templateUrl: './open-tok.component.html',
  styleUrls: ['./open-tok.component.scss'],
})
export class OpenTokComponent extends VideoContainer {
  canSwitchFeeds$: Observable<boolean>;
  showPatientFeedSwitcher = true;

  readonly SUBSCRIBER_DOM_ID = PATIENT_DOM_ID;
  readonly PUBLISHER_DOM_ID = INTERNAL_USER_DOM_ID;

  readonly AlertType = AlertType;
  readonly CallState = CallState;
  readonly VideoState = VideoState;

  constructor(private ngZone: NgZone, private logger: LoggerService, public provider: OpenTokService) {
    super();
  }

  init(sessionId: string) {
    this.canSwitchFeeds$ = this.provider.feedCount$.pipe(map(count => count > 1));

    this.provider.initializingPublishing$.subscribe({
      error: error => {
        this.ngZone.run(() => {
          this.showError({
            error,
            errorMessage: ErrorMessage.InitPublisherFailed,
            errorLogger: this.logger,
          });
          this.changeVideoState(VideoState.ProviderAVNotWorking);
        });
      },
    });

    this.provider.patientDropped$.pipe(take(1)).subscribe(() => {
      this.ngZone.run(() => {
        this.changeVideoState(VideoState.PatientDropped);
        this.provider.endCall();
      });
    });

    this.provider.init(sessionId);
  }

  switchFeed(event) {
    event.preventDefault();

    this.provider.switchFeed();
  }

  onActionClicked() {
    this.actionClicked.emit();
  }

  private showError(options: ShowErrorOptions) {
    this.error.emit(options);
  }
}
