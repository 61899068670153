import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { ApolloQueryResult } from 'apollo-client';
import gql from 'graphql-tag';
import { from, Observable, Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { FirebaseToken } from '@app/core/firebase/__generated__/FirebaseToken';
import { NewAngularFireAuth } from '@app/core/firebase/angular-fire-wrappers/new-angular-fire-auth';
import { RealtimeDbAuthService } from '@app/core/models/realtime-db';

export const GET_FIREBASE_TOKEN_QUERY = gql`
  query FirebaseToken {
    firebase {
      token
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class FirebaseAuthService implements RealtimeDbAuthService {
  loggingOut$ = new Subject<void>();

  constructor(private apollo: Apollo, private fireAuth: NewAngularFireAuth) {}

  get isAuthenticated(): Observable<boolean> {
    return this.fireAuth.authState.pipe(map(response => !!response && !!response.uid));
  }

  login(): Observable<boolean> {
    return this.apollo.query({ query: GET_FIREBASE_TOKEN_QUERY }).pipe(
      switchMap((result: ApolloQueryResult<FirebaseToken>) => {
        const token = result.data.firebase.token;
        return from(this.fireAuth.signInWithCustomToken(token));
      }),
      map(_ => true),
    );
  }

  logout() {
    this.loggingOut$.next();
    this.loggingOut$.complete();

    this.fireAuth.signOut();
  }
}
