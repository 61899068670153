<button
  class="btn px-3"
  [class.btn-primary]="!disabled"
  [class.btn-gray]="disabled"
  type="button"
  [disabled]="disabled"
  (click)="clickHandler($event)"
>
  <ng-content></ng-content>
</button>
