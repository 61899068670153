import { Component } from '@angular/core';

@Component({
  selector: 'omgui-svg-circle-arrow-up',
  template: `
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 15.75C12.2812 15.75 15.75 12.2812 15.75 8C15.75 3.71875 12.2812 0.25 8 0.25C3.71875 0.25 0.25 3.71875 0.25 8C0.25 12.2812 3.71875 15.75 8 15.75ZM1.25 8C1.25 4.3125 4.25 1.25 8 1.25C11.6875 1.25 14.75 4.28125 14.75 8C14.75 11.7188 11.7188 14.75 8 14.75C4.28125 14.75 1.25 11.75 1.25 8ZM4.125 7.625C3.96875 7.78125 3.96875 8 4.125 8.15625L4.34375 8.375C4.5 8.53125 4.71875 8.53125 4.875 8.375L7.46875 5.6875V11.625C7.46875 11.8438 7.625 12 7.84375 12H8.15625C8.34375 12 8.53125 11.8438 8.53125 11.625V5.6875L11.0938 8.375C11.25 8.53125 11.4688 8.53125 11.625 8.375L11.8438 8.15625C12 8 12 7.78125 11.8438 7.625L8.25 4.03125C8.09375 3.875 7.875 3.875 7.71875 4.03125L4.125 7.625Z"
        fill="black"
        fill-opacity="0.87"
      />
    </svg>
  `,
})
export class SvgCircleArrowUpComponent {}
