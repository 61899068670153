import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { CoreModule } from '@app/core/core.module';
import { VideoContainerModule } from '@app/video-container/video-container.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GraphQLModule } from './graphql.module';
import { MainWindowComponent } from './main-window/main-window.component';
import { UIModule } from './ui/ui.module';

@NgModule({
  declarations: [AppComponent, MainWindowComponent],
  imports: [AppRoutingModule, BrowserModule, CoreModule, GraphQLModule, UIModule, VideoContainerModule],
  bootstrap: [AppComponent],
})
export class AppModule {}
