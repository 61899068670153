import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'omgui-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
})
export class ToggleComponent {
  /** Whether the toggle is on or off */
  @Input() on = false;

  /** Emits whenever the toggle has been clicked. Changing the value through the input will not trigger an emission */
  @Output() toggleChanged = new EventEmitter<boolean>();

  /** @ignore */
  toggle() {
    this.on = !this.on;
    this.toggleChanged.emit(this.on);
  }
}
