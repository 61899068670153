import { Injectable } from '@angular/core';
import { AngularFireAction, AngularFireList, DatabaseSnapshot, SnapshotAction } from '@angular/fire/database';
import { Apollo } from 'apollo-angular';
import { ApolloQueryResult } from 'apollo-client';
import firebase from 'firebase/app';
import gql from 'graphql-tag';
import { cloneDeep } from 'lodash-es';
import { from, noop, Observable } from 'rxjs';
import { map, share, switchMap, take } from 'rxjs/operators';

import { LegacyAngularFireDatabase } from '@app/core/firebase/angular-fire-wrappers/legacy-angular-fire-database';
import { LegacyFirebaseNamespace } from '@app/core/firebase/legacy-project-services/__generated__/LegacyFirebaseNamespace';
import { ID } from '@app/core/models/id';
import { VirtualVisitStore } from '@app/core/models/realtime-db';
import {
  UnhydratedVirtualVisit,
  VideoCallType,
  VirtualVisitForUpdate,
  VirtualVisitState,
} from '@app/core/models/unhydrated-virtual-visit';
import { VirtualVisit } from '@app/core/models/virtual-visit';

export const GET_LEGACY_FIREBASE_NAMESPACE_QUERY = gql`
  query LegacyFirebaseNamespace {
    legacyFirebase {
      namespace
    }
  }
`;

export enum RawState {
  Waiting = 'waiting',
  InProgress = 'in_progress',
}

export interface RawVirtualVisit {
  state: RawState;
  user_id: number;
  waiting_since: number;
  session_id: string;
  answered_at?: any;
  answered_by?: number;
  reason_id?: number;
  started_at?: number;
}

@Injectable({
  providedIn: 'root',
})
export class LegacyFirebaseService implements VirtualVisitStore<any> {
  constructor(private fireDatabase: LegacyAngularFireDatabase, private apollo: Apollo) {}

  get(states: VirtualVisitState[]): Observable<UnhydratedVirtualVisit[]> {
    return this.list$.pipe(
      switchMap(list => {
        return list.snapshotChanges();
      }),
      map((snapshots: SnapshotAction<RawVirtualVisit>[]) => {
        return snapshots.map((fireAction: AngularFireAction<DatabaseSnapshot<RawVirtualVisit>>) => {
          const rawVisit = fireAction.payload.val();

          return this.transformRawVisit(fireAction.key, rawVisit);
        });
      }),
      map((visits: UnhydratedVirtualVisit[]) => {
        if (states.length === 0) {
          return visits;
        }

        return visits.filter(visit => states.includes(visit.visitState));
      }),
    );
  }

  getCall(id: ID): Observable<UnhydratedVirtualVisit> {
    return this.document$(id).pipe(
      switchMap((collection: AngularFireList<RawVirtualVisit>) => collection.valueChanges()),
      map((rawVisits: RawVirtualVisit[]) => {
        if (rawVisits.length > 0) {
          return this.transformRawVisit(id, rawVisits[0]);
        }
      }),
    );
  }

  update(visit: VirtualVisitForUpdate<any>): Observable<boolean> {
    const transformedVisit = {} as RawVirtualVisit;

    if (typeof visit.visitState !== 'undefined') {
      if (visit.visitState === VirtualVisitState.InProgress || visit.visitState === VirtualVisitState.Claimed) {
        transformedVisit.state = RawState.InProgress;
      } else {
        transformedVisit.state = RawState.Waiting;
      }
    }

    if (typeof visit.openTokSessionId !== 'undefined') {
      transformedVisit.session_id = visit.openTokSessionId;
    }

    if (typeof visit.claimedBy !== 'undefined') {
      if (visit.claimedBy) {
        transformedVisit.answered_by = +visit.claimedBy;
      } else {
        transformedVisit.answered_by = this.deleteFieldType();
      }
    }

    if (typeof visit.claimedAt !== 'undefined') {
      transformedVisit.answered_at = visit.claimedAt;
    }

    if (typeof visit.queuedBy !== 'undefined') {
      if (visit.queuedBy) {
        transformedVisit.user_id = +visit.queuedBy;
      } else {
        transformedVisit.user_id = this.deleteFieldType();
      }
    }

    if (typeof visit.queuedAt !== 'undefined') {
      transformedVisit.waiting_since = visit.queuedAt;
    }

    if (typeof visit.startedAt !== 'undefined') {
      transformedVisit.started_at = visit.startedAt;
    }

    return this.actionToObservable(list => list.update(visit.id, transformedVisit));
  }

  delete(id: ID): Observable<boolean> {
    return this.actionToObservable(list => list.remove(id));
  }

  claimCall(visit: VirtualVisit, claimedBy: ID): Observable<VirtualVisit> {
    return this.update({
      id: visit.id,
      visitState: VirtualVisitState.Claimed,
      claimedAt: this.serverTimeNow(),
      claimedBy: claimedBy,
    }).pipe(map(_ => cloneDeep(visit)));
  }

  endCall(id: ID): Observable<boolean> {
    return this.delete(id);
  }

  startCall(id: ID): Observable<boolean> {
    return this.update({
      id: id,
      visitState: VirtualVisitState.InProgress,
      startedAt: this.serverTimeNow(),
    });
  }

  unstartCall(id: ID): Observable<boolean> {
    return this.update({
      id: id,
      visitState: VirtualVisitState.Claimed,
      startedAt: this.deleteFieldType(),
    });
  }

  serverTimeNow(): any {
    return firebase.database.ServerValue.TIMESTAMP;
  }

  timeFieldFromDate(id: ID, date: Date): any {
    return date.getTime();
  }

  deleteFieldType(): any {
    return null;
  }

  unclaimCall(id: ID): Observable<boolean> {
    return this.endCall(id);
  }

  callEnded$(visitId: string): Observable<void> {
    return this.document$(visitId).pipe(
      switchMap((singletonList: AngularFireList<RawVirtualVisit>) =>
        singletonList.stateChanges(['child_removed']).pipe(map(_ => undefined)),
      ),
      take(1),
    );
  }

  private transformRawVisit(id: ID, rawVisit: RawVirtualVisit): UnhydratedVirtualVisit {
    let state: VirtualVisitState;

    if (rawVisit.state === RawState.InProgress) {
      if (rawVisit.started_at) {
        state = VirtualVisitState.InProgress;
      } else {
        state = VirtualVisitState.Claimed;
      }
    } else {
      state = VirtualVisitState.Queued;
    }

    const visit: UnhydratedVirtualVisit = {
      id: id,
      visitState: state,
      sessionId: rawVisit.session_id,
      videoCallType: VideoCallType.OpenTok,
      queuedBy: `${rawVisit.user_id}`,
      queuedAt: new Date(rawVisit.waiting_since),
    };

    if (rawVisit.answered_by) {
      visit.claimedBy = `${rawVisit.answered_by}`;
    }

    if (rawVisit.answered_at) {
      visit.claimedAt = new Date(rawVisit.answered_at as number);
    }

    if (rawVisit.started_at) {
      visit.startedAt = new Date(rawVisit.started_at as number);
    }

    return visit;
  }

  private actionToObservable(action: (list) => Promise<null>): Observable<boolean> {
    const observable = this.list$.pipe(
      switchMap(list => from(action(list))),
      map(_ => true),
      share(),
    );

    observable.subscribe({ error: noop });

    return observable;
  }

  private get list$(): Observable<AngularFireList<RawVirtualVisit>> {
    return this.apollo.query({ query: GET_LEGACY_FIREBASE_NAMESPACE_QUERY }).pipe(
      map((result: ApolloQueryResult<LegacyFirebaseNamespace>) => {
        const sessionsPath = `${result.data.legacyFirebase.namespace}/video/sessions`;
        return this.fireDatabase.list<RawVirtualVisit>(sessionsPath, ref => ref.orderByChild('waiting_since'));
      }),
    );
  }

  private document$(id: ID): Observable<AngularFireList<RawVirtualVisit>> {
    return this.apollo.query({ query: GET_LEGACY_FIREBASE_NAMESPACE_QUERY }).pipe(
      map((result: ApolloQueryResult<LegacyFirebaseNamespace>) => {
        const sessionsPath = `${result.data.legacyFirebase.namespace}/video/sessions`;
        return this.fireDatabase.list<RawVirtualVisit>(sessionsPath, ref => ref.orderByKey().equalTo(id));
      }),
    );
  }
}
