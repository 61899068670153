import { Component, ContentChild, Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[omgui-nav-item-icon]',
})
export class NavItemIconDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({
  selector: '[omgui-nav-item-end]',
})
export class NavItemEndContentDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Component({
  selector: 'omgui-nav-item',
  templateUrl: './nav-item.component.html',
  styleUrls: ['./nav-item.component.scss'],
})
export class NavItemComponent {
  /** Describes the location this item routes to */
  @Input() title: string;
  /** Sets the style to highlight it being selected */
  @Input() selected = false;
  /** Icon */
  @ContentChild(NavItemIconDirective) icon: NavItemIconDirective;
  /** End content, such as a count for example */
  @ContentChild(NavItemEndContentDirective) end: NavItemEndContentDirective;
}
