import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import Rollbar from 'rollbar';
import { Observable } from 'rxjs';
import { finalize, map, take, tap } from 'rxjs/operators';

import { ClearStorageService } from '@app/core/clear-storage.service';
import { ConfigService } from '@app/core/config.service';
import { CookieStorageService } from '@app/core/cookie-storage.service';
import { RollbarService } from '@app/core/rollbar';
import { WindowService } from '@app/core/window.service';

export interface AccessTokenResponse {
  access_token: string;
  created_at: number;
  scope: string;
  token_type: string;
}

class BlankTokenError extends Error {
  constructor() {
    super('Received a blank OAuth token.');
    this.name = 'BlankTokenError';
  }
}

@Injectable({
  providedIn: 'root',
})
export class OAuthService {
  static readonly StorageKey = 'token';

  private baseUrl = this.configService.environment.oauth2.providerUrl;
  private oauth2 = this.configService.environment.oauth2;

  constructor(
    private cookieStorageService: CookieStorageService,
    private http: HttpClient,
    private configService: ConfigService,
    private windowService: WindowService,
    private clearStorageService: ClearStorageService,
    @Inject(RollbarService) private rollbar: Rollbar,
  ) {}

  get isAuthenticated() {
    return !!this.token;
  }

  get token() {
    return this.cookieStorageService.getItem(OAuthService.StorageKey);
  }

  login() {
    this.clearStorageService.clearAll();
    this.windowService.redirect(this.buildLoginUrl());
  }

  logout() {
    const token = this.cookieStorageService.getItem(OAuthService.StorageKey);
    this.clearStorageService.clearAll();

    this.http
      .post(`${this.baseUrl}/oauth/revoke`, { token })
      .pipe(finalize(() => this.windowService.redirect(`${this.baseUrl}/admin/auth/logout`)))
      .subscribe();
  }

  verifyAccessToken(code: string): Observable<string | boolean> {
    const accessTokenRequestParams = {
      code,
      client_id: this.oauth2.clientId,
      grant_type: 'authorization_code',
      redirect_uri: window.location.origin,
      code_verifier: 'codechallenge',
    };

    return this.http.post<AccessTokenResponse>(`${this.baseUrl}/oauth/token`, accessTokenRequestParams).pipe(
      take(1),
      map(response => response.access_token),
      tap((token: string) => {
        if (token === '') {
          this.rollbar.error(new BlankTokenError());
        }

        this.setAuthToken(token);
      }),
    );
  }

  private buildLoginUrl() {
    const url = `${this.baseUrl}/oauth/authorize?`;
    const paramsObject = {
      client_id: this.oauth2.clientId,
      redirect_uri: window.location.origin,
      response_type: 'code',
      code_challenge: 'codechallenge',
      code_challenge_method: 'plain',
    };
    const params = new HttpParams({ fromObject: paramsObject });

    return url + params.toString();
  }

  private setAuthToken(token: string) {
    this.cookieStorageService.setItem(OAuthService.StorageKey, token);
  }
}
