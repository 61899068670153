import { Pipe, PipeTransform } from '@angular/core';
import { padStart } from 'lodash-es';

import { DateHelpers } from '@app/utils/date-helpers';

@Pipe({ name: 'relativeTime', pure: true })
export class RelativeTimePipe implements PipeTransform {
  constructor() {}

  transform({ left, right }: { left: Date; right: Date }) {
    const timeDifference = DateHelpers.absoluteDifference(left, right);
    const seconds = padStart(timeDifference.seconds.toString(), 2, '0');

    return `${timeDifference.minutes}:${seconds}`;
  }
}
