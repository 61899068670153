import { NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase';
import { of as observableOf } from 'rxjs';
import UserCredential = firebase.auth.UserCredential;

class AngularFireAuthMock extends AngularFireAuth {
  authState = observableOf(null);
  signInWithCustomToken = (token: string): Promise<UserCredential> => Promise.resolve(null);
}

export function angularFireAuthFactory(
  platformId: Object,
  name: string,
  zone: NgZone,
  config: object,
  environmentName: string,
): AngularFireAuth {
  if (environmentName === 'firebase-emulator') {
    return new AngularFireAuthMock({}, name, platformId, zone);
  } else {
    return new AngularFireAuth(config, name, platformId, zone);
  }
}
