<omgui-alert
  *ngIf="
    showPatientFeedSwitcher &&
    (canSwitchFeeds$ | async) &&
    callState === CallState.Started &&
    videoState === VideoState.Started
  "
  [type]="AlertType.Warning"
  (close)="showPatientFeedSwitcher = false"
>
  Trouble with the patient's video feed? Try clicking <a (click)="switchFeed($event)" href="#">here</a>.
</omgui-alert>

<div
  [id]="SUBSCRIBER_DOM_ID"
  [class.d-none]="
    callState === CallState.Unstarted ||
    callState === CallState.PatientEnded ||
    videoState === VideoState.PatientDropped
  "
></div>
<div
  [id]="PUBLISHER_DOM_ID"
  [ngClass]="callState === CallState.Started ? 'call-started border border-white rounded' : 'call-claimed'"
  *ngIf="videoState !== VideoState.PatientDropped"
></div>

<div class="d-flex flex-column align-items-center control-overlay">
  <om-status-message
    class="message text-white"
    [status]="{ callState: callState, videoState: videoState }"
    [patientName]="patientPreferredName"
  ></om-status-message>
  <om-action-button
    [disabled]="pageState | isLoadingState"
    [status]="{ callState: callState, videoState: videoState }"
    (click)="onActionClicked()"
  ></om-action-button>
</div>
