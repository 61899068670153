import { NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';

import { ConfigService } from '@app/core/config.service';
import { angularFireAuthFactory } from '@app/core/firebase/angular-fire-wrappers/angular-fire-auth-shim';

export class NewAngularFireAuth extends AngularFireAuth {}

export function newAngularFireAuthFactory(platformId: Object, zone: NgZone, config: ConfigService): NewAngularFireAuth {
  return angularFireAuthFactory(
    platformId,
    'firebase',
    zone,
    config.environment.firebase,
    config.environment.environmentName,
  );
}
