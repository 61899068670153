import { Component, ContentChild, ContentChildren, Directive, Input, QueryList, TemplateRef } from '@angular/core';

import { PageState } from '@app/ui/pipes/page-state.pipe';

@Directive({
  selector: '[omgui-table-content]',
})
export class TableContentDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({
  selector: 'omgui-table-col',
})
export class TableColDirective {
  @ContentChildren(TableContentDirective) contentBlocks: QueryList<TableContentDirective>;
}

@Directive({
  selector: 'omgui-table-row',
})
export class TableRowDirective {
  @ContentChildren(TableColDirective) cols: QueryList<TableColDirective>;
}

@Directive({
  selector: '[omgui-table-empty-state]',
})
export class TableEmptyStateDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({
  selector: '[omgui-table-header]',
})
export class TableHeaderDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Component({
  selector: 'omgui-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent {
  /** Page state */
  @Input() pageState: PageState;
  /** Error message to show if the page is in error */
  @Input() errorMessage: string;
  /** Header template/content */
  @ContentChild(TableHeaderDirective) header: TableHeaderDirective;
  /** Empty state template/content */
  @ContentChild(TableEmptyStateDirective) emptyState: TableEmptyStateDirective;
  /** Row templates/content */
  @ContentChildren(TableRowDirective) rows: QueryList<TableRowDirective>;

  /** @ignore */
  PageState = PageState;
}
