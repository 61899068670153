import { Injectable } from '@angular/core';
import { AngularFirestoreCollection } from '@angular/fire/firestore';
import { QueryFn } from '@angular/fire/firestore/interfaces';
import { Apollo } from 'apollo-angular';
import { ApolloQueryResult } from 'apollo-client';
import firebase from 'firebase/app';
import gql from 'graphql-tag';
import cloneDeep from 'lodash-es/cloneDeep';
import pick from 'lodash-es/pick';
import sortBy from 'lodash-es/sortBy';
import { from, noop, Observable, of as observableOf } from 'rxjs';
import { filter, map, share, switchMap, take, tap } from 'rxjs/operators';

import { FirebaseNamespace } from '@app/core/firebase/__generated__/FirebaseNamespace';
import { NewAngularFirestore } from '@app/core/firebase/angular-fire-wrappers/new-angular-firestore';
import { ID } from '@app/core/models/id';
import { VirtualVisitStore } from '@app/core/models/realtime-db';
import {
  TIME_FIELDS,
  UnhydratedVirtualVisit,
  VideoCallType,
  VirtualVisitEndedBy,
  VirtualVisitForCreate,
  VirtualVisitForUpdate,
  VirtualVisitState,
} from '@app/core/models/unhydrated-virtual-visit';
import { VirtualVisit } from '@app/core/models/virtual-visit';
import { ZoomService } from '@app/core/zoom/zoom.service';
import Transaction = firebase.firestore.Transaction;

export interface RawVirtualVisit {
  id: string;
  visitState: VirtualVisitState;
  queuedBy: ID;
  queuedAt: firebase.firestore.Timestamp;
  licensingBody: string;
  openTokSessionId?: string;
  zoomMeetingId?: string;
  reasonForVisit?: string;
  claimedAt?: firebase.firestore.Timestamp;
  claimedBy?: ID;
  startedAt?: firebase.firestore.Timestamp;
}

export const GET_FIREBASE_NAMESPACE = gql`
  query FirebaseNamespace {
    firebase {
      namespace
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class FirestoreService implements VirtualVisitStore<firebase.firestore.FieldValue> {
  constructor(private firestore: NewAngularFirestore, private apollo: Apollo, private zoomService: ZoomService) {}

  get(states: VirtualVisitState[]): Observable<UnhydratedVirtualVisit[]> {
    const queryFn = ref => ref.where('visitState', 'in', states);

    return this.virtualVisits$(queryFn).pipe(
      switchMap((collection: AngularFirestoreCollection<RawVirtualVisit>) => {
        return collection.valueChanges({ idField: 'id' });
      }),
      map((rawVisits: RawVirtualVisit[]) => {
        const transformedVisits = rawVisits.map(rawVisit => this.transformRawVisit(rawVisit));

        return sortBy(transformedVisits, ['queuedAt']);
      }),
    );
  }

  getCall(id: ID): Observable<UnhydratedVirtualVisit> {
    return this.virtualVisits$().pipe(
      switchMap((collection: AngularFirestoreCollection<RawVirtualVisit>) =>
        collection.doc<RawVirtualVisit>(id).valueChanges(),
      ),
      map(rawVisit => {
        rawVisit.id = id;
        return this.transformRawVisit(rawVisit);
      }),
    );
  }

  create(virtualVisit: VirtualVisitForCreate<firebase.firestore.FieldValue>): Observable<boolean> {
    return this.actionToObservable(collection => collection.add(virtualVisit as RawVirtualVisit));
  }

  update(virtualVisit: VirtualVisitForUpdate<firebase.firestore.FieldValue>): Observable<boolean> {
    const { id, ...visit } = virtualVisit;

    return this.actionToObservable(collection => collection.doc(id).update(visit as RawVirtualVisit));
  }

  delete(id: ID): Observable<boolean> {
    return this.actionToObservable(collection => collection.doc(id).delete());
  }

  claimCall(virtualVisit: VirtualVisit, claimedBy: ID): Observable<VirtualVisit> {
    const claimedVirtualVisit = cloneDeep(virtualVisit);
    const visitForUpdate: VirtualVisitForUpdate<firebase.firestore.FieldValue> = {
      id: virtualVisit.id,
      visitState: VirtualVisitState.Claimed,
      claimedAt: this.serverTimeNow(),
      claimedBy: claimedBy,
    };
    let visitForUpdate$: Observable<VirtualVisitForUpdate<firebase.firestore.FieldValue>>;

    if (virtualVisit.videoCallType === VideoCallType.Zoom) {
      visitForUpdate$ = this.zoomService.createMeeting().pipe(
        tap((zoomMeetingId: string) => {
          claimedVirtualVisit.sessionId = zoomMeetingId;
        }),
        map((zoomMeetingId: string) => {
          visitForUpdate.zoomMeetingId = zoomMeetingId;
          return visitForUpdate;
        }),
      );
    } else {
      visitForUpdate$ = observableOf(visitForUpdate);
    }

    return visitForUpdate$.pipe(
      switchMap(visit => this.update(visit)),
      map(_ => claimedVirtualVisit),
    );
  }

  endCall(id: ID): Observable<boolean> {
    const endedState = {
      visitState: VirtualVisitState.Ended,
      endedAt: this.serverTimeNow(),
      endedBy: VirtualVisitEndedBy.InternalUser,
    };

    return this.actionToObservable((collection: AngularFirestoreCollection<RawVirtualVisit>) =>
      this.firestore.firestore.runTransaction((transaction: Transaction) => {
        const reference = collection.doc(id).ref;

        return transaction.get(reference).then((document: firebase.firestore.DocumentSnapshot<RawVirtualVisit>) => {
          if (document.data().visitState === VirtualVisitState.InProgress) {
            transaction.update(reference, endedState);
          }
        });
      }),
    );
  }

  startCall(id: ID): Observable<boolean> {
    return this.update({
      id: id,
      visitState: VirtualVisitState.InProgress,
      startedAt: this.serverTimeNow(),
    });
  }

  unstartCall(id: ID): Observable<boolean> {
    return this.update({
      id: id,
      visitState: VirtualVisitState.Claimed,
      startedAt: this.deleteFieldType(),
    });
  }

  serverTimeNow(): firebase.firestore.FieldValue {
    return firebase.firestore.FieldValue.serverTimestamp();
  }

  timeFieldFromDate(id: ID, date: Date): firebase.firestore.FieldValue {
    return firebase.firestore.Timestamp.fromDate(date);
  }

  deleteFieldType(): firebase.firestore.FieldValue {
    return firebase.firestore.FieldValue.delete();
  }

  unclaimCall(id: ID): Observable<boolean> {
    return this.update({
      id: id,
      visitState: VirtualVisitState.Queued,
      claimedAt: this.deleteFieldType(),
      claimedBy: this.deleteFieldType(),
    });
  }

  callEnded$(visitId: string): Observable<void> {
    return this.virtualVisits$().pipe(
      switchMap((collection: AngularFirestoreCollection<RawVirtualVisit>) => collection.doc(visitId).valueChanges()),
      filter(
        (visit: RawVirtualVisit) =>
          visit.visitState === VirtualVisitState.Ended || visit.visitState === VirtualVisitState.Cancelled,
      ),
      map(_ => undefined),
      take(1),
    );
  }

  private transformRawVisit(rawVisit): UnhydratedVirtualVisit {
    const visit = {
      ...pick(rawVisit, ['id', 'visitState', 'licensingBody', 'reasonForVisit', 'queuedBy', 'claimedBy', 'endedBy']),
    } as UnhydratedVirtualVisit;

    TIME_FIELDS.forEach(field => {
      if (rawVisit[field]) {
        visit[field] = rawVisit[field].toDate();
      }
    });

    if (rawVisit.openTokSessionId) {
      visit.videoCallType = VideoCallType.OpenTok;
      visit.sessionId = rawVisit.openTokSessionId;
    } else {
      visit.videoCallType = VideoCallType.Zoom;
      visit.sessionId = rawVisit.zoomMeetingId;
    }

    return visit;
  }

  private actionToObservable(action: (collection) => Promise<void>, queryFn?: QueryFn): Observable<boolean> {
    const observable = this.virtualVisits$(queryFn).pipe(
      switchMap(collection => from(action(collection))),
      map(_ => true),
      share(),
    );

    observable.subscribe({ error: noop });

    return observable;
  }

  private virtualVisits$(queryFn?: QueryFn): Observable<AngularFirestoreCollection<RawVirtualVisit>> {
    return this.apollo.query({ query: GET_FIREBASE_NAMESPACE }).pipe(
      map((result: ApolloQueryResult<FirebaseNamespace>) => {
        const path = `${result.data.firebase.namespace}/virtualVisitsService/virtualVisits`;
        return this.firestore.collection<RawVirtualVisit>(path, queryFn);
      }),
    );
  }
}
