import { Inject, Injectable } from '@angular/core';
import sha512 from 'crypto-js/sha512';
import Rollbar from 'rollbar';
import SumoLogger, { SumoLoggerOptions } from 'sumo-logger';

import { ConfigService } from '@app/core/config.service';
import { ErrorLogger, Logger } from '@app/core/models/logger';
import { OAuthService } from '@app/core/o-auth/o-auth.service';
import { RollbarService } from '@app/core/rollbar';

@Injectable({
  providedIn: 'root',
})
export class LoggerService implements ErrorLogger, Logger {
  private readonly logger: SumoLogger;

  constructor(
    private config: ConfigService,
    private oAuthService: OAuthService,
    @Inject(RollbarService) private rollbar: Rollbar,
  ) {
    const sessionKey = sha512(oAuthService.token).toString();
    const options: SumoLoggerOptions = {
      endpoint: config.loggerEndpoint,
      sendErrors: false,
      sessionKey: sessionKey,
      sourceName: 'virtual-visits',
      sourceCategory: `${this.config.environment.environmentName}/dashboard`,
      clientUrl: window.location.href,
    };

    this.logger = new SumoLogger(options);
  }

  log(message: string, prefix?: string): void {
    if (prefix) {
      message = `${prefix} -- ${message}`;
    }

    if (this.config.environment.devMode || !this.logger) {
      // tslint:disable-next-line:no-console
      console.log(`LoggerService: ${message}`);
    } else {
      this.logger.log(message);
    }
  }

  error(error: Error): void {
    this.log(error.stack);
  }
}
