import { Component, Input } from '@angular/core';

@Component({
  selector: 'omgui-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
})
export class BadgeComponent {
  /** Whether or not the badge has active or inactive styling */
  @Input() active = true;

  constructor() {}
}
