import { Injectable } from '@angular/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { ClearStorageService } from '@app/core/clear-storage.service';
import { FirebaseAuthService } from '@app/core/firebase/firebase-auth.service';
import { LegacyFirebaseAuthService } from '@app/core/firebase/legacy-project-services/legacy-firebase-auth.service';
import { RealtimeDbAuthService } from '@app/core/models/realtime-db';

@Injectable({
  providedIn: 'root',
})
export class AuthGlueService implements RealtimeDbAuthService {
  loggingOut$ = new Subject<void>();

  constructor(
    private auth: FirebaseAuthService,
    private legacyAuth: LegacyFirebaseAuthService,
    private clearStorageService: ClearStorageService,
  ) {}

  get isAuthenticated(): Observable<boolean> {
    return combineLatest([this.auth.isAuthenticated, this.legacyAuth.isAuthenticated]).pipe(
      map(([authAuthenticated, legacyAuthAuthenticated]) => authAuthenticated && legacyAuthAuthenticated),
    );
  }

  login(): Observable<boolean> {
    return combineLatest([this.auth.login(), this.legacyAuth.login()]).pipe(
      map(([newAuthResult, legacyAuthResult]) => newAuthResult && legacyAuthResult),
    );
  }

  logout(): void {
    this.loggingOut$.next();
    this.loggingOut$.complete();

    this.clearStorageService.clearAll();
    this.auth.logout();
    this.legacyAuth.logout();
  }
}
