import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { ApolloQueryResult } from 'apollo-client';
import gql from 'graphql-tag';
import { from, Observable, Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { LegacyAngularFireAuth } from '@app/core/firebase/angular-fire-wrappers/legacy-angular-fire-auth';
import { LegacyFirebaseToken } from '@app/core/firebase/legacy-project-services/__generated__/LegacyFirebaseToken';
import { RealtimeDbAuthService } from '@app/core/models/realtime-db';

export const GET_LEGACY_FIREBASE_TOKEN_QUERY = gql`
  query LegacyFirebaseToken {
    legacyFirebase {
      token
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class LegacyFirebaseAuthService implements RealtimeDbAuthService {
  loggingOut$ = new Subject<void>();

  constructor(private apollo: Apollo, private fireAuth: LegacyAngularFireAuth) {}

  get isAuthenticated(): Observable<boolean> {
    return this.fireAuth.authState.pipe(map(response => !!response && !!response.uid));
  }

  login(): Observable<boolean> {
    return this.apollo.query({ query: GET_LEGACY_FIREBASE_TOKEN_QUERY }).pipe(
      switchMap((result: ApolloQueryResult<LegacyFirebaseToken>) => {
        const token = result.data.legacyFirebase.token;
        return from(this.fireAuth.signInWithCustomToken(token));
      }),
      map(_ => true),
    );
  }

  logout() {
    this.loggingOut$.next();
    this.loggingOut$.complete();

    this.fireAuth.signOut();
  }
}
