import { NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';

import { ConfigService } from '@app/core/config.service';
import { angularFireAuthFactory } from '@app/core/firebase/angular-fire-wrappers/angular-fire-auth-shim';

export class LegacyAngularFireAuth extends AngularFireAuth {}

export function legacyAngularFireAuthFactory(
  platformId: Object,
  zone: NgZone,
  config: ConfigService,
): LegacyAngularFireAuth {
  return angularFireAuthFactory(
    platformId,
    'legacy_firebase',
    zone,
    config.environment.legacy_firebase,
    config.environment.environmentName,
  );
}
