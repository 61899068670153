import { Injectable } from '@angular/core';

import { LocalStorageService } from '@app/core/local-storage.service';

export interface WindowProperties {
  width: number;
  height: number;
  left: number;
  top: number;
}

@Injectable({
  providedIn: 'root',
})
export class WindowPropertiesService {
  static VideoPropertiesKey = 'window_properties_video';
  static DefaultVideoProperties: WindowProperties = { width: 480, height: 720, left: 0, top: 0 };

  constructor(private storage: LocalStorageService) {}

  saveVideoProperties(properties: WindowProperties) {
    this.storage.setItem(WindowPropertiesService.VideoPropertiesKey, JSON.stringify(properties));
  }

  getVideoPropertiesForDOM(): string {
    const storedProperties = this.storage.getItem(WindowPropertiesService.VideoPropertiesKey);
    if (storedProperties === undefined) {
      return this.convertToDomString(WindowPropertiesService.DefaultVideoProperties);
    } else {
      return this.convertToDomString(JSON.parse(storedProperties));
    }
  }

  private convertToDomString(windowProperties: WindowProperties): string {
    const { left, height, width, top } = windowProperties;
    return `width=${width}, height=${height}, left=${left}, top=${top}`;
  }
}
