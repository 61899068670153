import { Injectable } from '@angular/core';
import firebase from 'firebase/app';
import sortBy from 'lodash-es/sortBy';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FirestoreService } from '@app/core/firebase/firestore.service';
import { LegacyFirebaseService } from '@app/core/firebase/legacy-project-services/legacy-firebase.service';
import { LocalStorageService } from '@app/core/local-storage.service';
import { ID } from '@app/core/models/id';
import { VirtualVisitStore } from '@app/core/models/realtime-db';
import {
  UnhydratedVirtualVisit,
  VirtualVisitForCreate,
  VirtualVisitForUpdate,
  VirtualVisitState,
} from '@app/core/models/unhydrated-virtual-visit';
import { VirtualVisit } from '@app/core/models/virtual-visit';

enum StoreName {
  LegacyFirebaseService = 'LegacyFirebaseService',
  FirestoreService = 'FirestoreService',
}

@Injectable({
  providedIn: 'root',
})
export class DbGlueService implements VirtualVisitStore<any> {
  static StorageKey = 'visit_ids_to_service';

  constructor(
    private firestoreService: FirestoreService,
    private legacyFireDatabaseService: LegacyFirebaseService,
    private storageService: LocalStorageService,
  ) {}

  create(visit: VirtualVisitForCreate<firebase.firestore.FieldValue>): Observable<boolean> {
    return this.firestoreService.create(visit);
  }

  delete(id: string): Observable<boolean> {
    return this.idToService(id).delete(id);
  }

  get(states: VirtualVisitState[]): Observable<UnhydratedVirtualVisit[]> {
    return combineLatest([this.firestoreService.get(states), this.legacyFireDatabaseService.get(states)]).pipe(
      map(([newVisits, legacyVisits]) => {
        const newMappings: { [id: string]: StoreName } = {};

        newVisits.forEach(visit => {
          newMappings[visit.id] = StoreName.FirestoreService;
        });

        legacyVisits.forEach(visit => {
          newMappings[visit.id] = StoreName.LegacyFirebaseService;
        });

        const raw = this.storageService.getItem(DbGlueService.StorageKey);
        const existingMappings = raw ? JSON.parse(raw) : {};
        const mergedMappings = JSON.stringify({
          ...existingMappings,
          ...newMappings,
        });

        this.storageService.setItem(DbGlueService.StorageKey, mergedMappings);

        return sortBy(newVisits.concat(legacyVisits), ['queuedAt']);
      }),
    );
  }

  getCall(id: ID): Observable<UnhydratedVirtualVisit> {
    return this.idToService(id).getCall(id);
  }

  update(visit: VirtualVisitForUpdate<firebase.firestore.FieldValue>): Observable<boolean> {
    return this.idToService(visit.id).update(visit);
  }

  serverTimeNow(id: ID): any {
    return this.idToService(id).serverTimeNow(id);
  }

  timeFieldFromDate(id: ID, date: Date): any {
    return this.idToService(id).timeFieldFromDate(id, date);
  }

  deleteFieldType(id: ID): any {
    return this.idToService(id).deleteFieldType(id);
  }

  claimCall(visit: VirtualVisit, claimedBy: ID): Observable<VirtualVisit> {
    return this.idToService(visit.id).claimCall(visit, claimedBy);
  }

  unclaimCall(id: ID): Observable<boolean> {
    return this.idToService(id).unclaimCall(id);
  }

  startCall(id: ID): Observable<boolean> {
    return this.idToService(id).startCall(id);
  }

  unstartCall(id: ID): Observable<boolean> {
    return this.idToService(id).unstartCall(id);
  }

  endCall(visitId: ID): Observable<boolean> {
    return this.idToService(visitId).endCall(visitId);
  }

  callEnded$(visitId: string): Observable<void> {
    return this.idToService(visitId).callEnded$(visitId);
  }

  private idToService(id: ID): VirtualVisitStore<any> {
    const storeName = JSON.parse(this.storageService.getItem(DbGlueService.StorageKey))[id];

    if (storeName === StoreName.FirestoreService) {
      return this.firestoreService;
    } else {
      return this.legacyFireDatabaseService;
    }
  }
}
