import { Component, EventEmitter, Input, Output } from '@angular/core';

export enum AlertType {
  Success = 'success',
  Info = 'info',
  Warning = 'warning',
  Danger = 'danger',
  Primary = 'primary',
  Secondary = 'secondary',
  Light = 'light',
  Dark = 'dark',
}

@Component({
  selector: 'omgui-alert',
  templateUrl: './alert.component.html',
  styles: [],
})
export class AlertComponent {
  /** Determines the type of alert */
  @Input() type: AlertType;

  /** Fires when the close icon is clicked. */
  @Output() close = new EventEmitter<void>();
}
