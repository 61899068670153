import { Component, ElementRef, ViewChild } from '@angular/core';
import { combineLatest, fromEvent } from 'rxjs';

import { CallState, VideoContainer, VideoState } from '@app/core/models/video-container';
import { MeetingConfig, ZoomService } from '@app/core/zoom/zoom.service';
import { PageState } from '@app/ui/pipes/page-state.pipe';

@Component({
  selector: 'om-zoom',
  templateUrl: './zoom.component.html',
  styleUrls: ['./zoom.component.scss'],
})
export class ZoomComponent extends VideoContainer {
  @ViewChild('zoom') zoomFrame: ElementRef;

  readonly PageState = PageState;
  readonly CallState = CallState;
  readonly VideoState = VideoState;

  constructor(public provider: ZoomService) {
    super();
  }

  init(meetingId: string): void {
    const zoomWindow = this.zoomFrame.nativeElement.contentWindow;

    zoomWindow.addEventListener('zoom-ended', () => {
      this.changeCallState(CallState.PatientEnded);
    });

    combineLatest([this.provider.init(meetingId), fromEvent(zoomWindow, 'zoom-loaded')]).subscribe({
      next: ([meetingConfig, _]) => {
        this.changeCallState(CallState.Started);
        this.changePageState(PageState.SUCCESS);
        this.changeVideoState(VideoState.Started);

        const event = new CustomEvent<MeetingConfig>('zoom-initialize', { detail: meetingConfig });
        zoomWindow.dispatchEvent(event);
      },
    });
  }
}
