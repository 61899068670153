import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export interface MultiSelectItem {
  label: string;
}

@Component({
  selector: 'omgui-multiselect',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => MultiselectComponent),
    },
  ],
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.scss'],
})
export class MultiselectComponent implements ControlValueAccessor {
  /** All the possible values that can be selected. This should be an array of `Item`s, where `Item` is an
   *  object with a `label: string` property. The `label` is what gets displayed as the option.
   */
  @Input() items: MultiSelectItem[];

  /** Placeholder text for the input */
  @Input() placeholder: string;

  /** Truncate items after this many options */
  @Input() truncateAfter = 2;

  /** @ignore */
  private _onChange: Function;

  /** @ignore */
  currentValue;

  /** @ignore */
  writeValue(value) {
    this.currentValue = value;
  }

  /** @ignore */
  registerOnChange(fn: (_: any) => void): void {
    this._onChange = fn;
  }

  /** @ignore */
  registerOnTouched() {}

  /** @ignore */
  handleValueChange(newValue) {
    this._onChange(newValue);
  }
}
