<div *ngIf="pageState && pageState !== PageState.LOADING" class="card">
  <div class="card-header" *ngIf="header">
    <h4 class="my-3"><ng-container *ngTemplateOutlet="header.templateRef"></ng-container></h4>
  </div>

  <ng-container *ngIf="pageState === PageState.SUCCESS; then tableRows; else tableError"></ng-container>

  <ng-template #tableRows>
    <div class="list-group list-group-flush" *ngIf="rows.length > 0">
      <ng-container *ngFor="let row of rows">
        <div class="list-group-item">
          <div class="d-flex justify-content-between">
            <ng-container *ngFor="let col of row.cols; first as isFirst; last as isLast">
              <div
                class="col d-flex flex-column justify-content-between"
                [class.text-right]="isLast"
                [class.align-items-end]="isLast"
                [class.pl-0]="isFirst"
                [class.pr-0]="isLast"
              >
                <ng-container *ngFor="let block of col.contentBlocks">
                  <div><ng-template *ngTemplateOutlet="block.templateRef"></ng-template></div>
                </ng-container>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>

    <ng-container *ngIf="rows.length === 0 && emptyState">
      <div class="card-body">
        <div class="card-text text-muted text-center m-3" data-cy="empty-state">
          <ng-container *ngTemplateOutlet="emptyState.templateRef"></ng-container>
        </div>
      </div>
    </ng-container>
  </ng-template>

  <ng-template #tableError>
    <div class="list-group list-group-flush">
      <div class="list-group-item">
        <div class="d-flex justify-content-between">
          <div class="col d-flex flex-column justify-con  tent-between">
            <div>{{ errorMessage || 'Oh no, something went wrong!' }}</div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
