import { Injectable } from '@angular/core';
import firebase from 'firebase/app';

import { AuthGlueService } from '@app/core/firebase/auth-glue.service';
import { DbGlueService } from '@app/core/firebase/db-glue.service';
import { RealtimeDbAuthService, VirtualVisitStore } from '@app/core/models/realtime-db';

@Injectable({
  providedIn: 'root',
})
export class RealtimeDbService {
  auth: RealtimeDbAuthService;
  store: VirtualVisitStore<firebase.firestore.FieldValue>;

  constructor(private firebaseAuthService: AuthGlueService, private firestoreService: DbGlueService) {
    this.auth = firebaseAuthService;
    this.store = firestoreService;
  }
}
