import { Component, Input, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'omgui-primary-button',
  templateUrl: './primary-button.component.html',
  styleUrls: ['./primary-button.component.scss'],
})
export class PrimaryButtonComponent {
  /** Whether or not the button is disabled */
  @Input() disabled = false;

  /** Button `click` event emitter */
  @Output() click = new EventEmitter();

  constructor() {}

  /** @ignore */
  clickHandler(event: MouseEvent) {
    event.stopPropagation();
    this.click.emit(event);
  }
}
