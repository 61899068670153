<ng-select
  class="custom"
  [items]="items"
  [multiple]="true"
  [placeholder]="placeholder"
  [(ngModel)]="currentValue"
  (change)="handleValueChange($event)"
>
  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
    <div class="ng-value" *ngFor="let item of items | slice: 0:truncateAfter">
      <span class="ng-value-label">{{ item.label }}</span>
      <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">
        <div class="inline-icon-with-text">
          <span class="icon-anchor">&nbsp;</span>
          <omgui-svg-x class="text-gray-dark"></omgui-svg-x>
        </div>
      </span>
    </div>
    <div class="ng-value" *ngIf="items.length > truncateAfter">
      <span class="ng-value-label">{{ items.length - truncateAfter }} more...</span>
    </div>
  </ng-template>
</ng-select>
