import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { BroadcastableChannel, BroadcastChannelService } from './broadcast-channel.service';
import { ID } from './models/id';

export interface WindowIdentifiers {
  visitId: ID;
  sessionId: ID;
}

interface CloseWindowMessage {
  windowIdentifiers: WindowIdentifiers;
}

@Injectable({
  providedIn: 'root',
})
export class VideoWindowService {
  static readonly CLOSE_WINDOW_CHANNEL_NAME = 'CLOSE_WINDOW_CHANNEL';
  private closeWindowChannel: BroadcastableChannel;
  private readonly _closeWindow$ = new Subject<CloseWindowMessage>();

  constructor(broadCastChannelService: BroadcastChannelService) {
    this.closeWindowChannel = broadCastChannelService.createBroadcastChannel(
      VideoWindowService.CLOSE_WINDOW_CHANNEL_NAME,
    );

    this.closeWindowChannel.addEventListener('message', (event: MessageEvent) => {
      const message: CloseWindowMessage = event.data;
      this._closeWindow$.next(message);
    });
  }

  getPath(ids: WindowIdentifiers): string {
    return `/video/${ids.visitId}/${ids.sessionId}`;
  }

  postCloseWindowMessage(ids: WindowIdentifiers) {
    const message: CloseWindowMessage = { windowIdentifiers: ids };
    this.closeWindowChannel.postMessage(message);
  }

  getCloseWindow$(ids: WindowIdentifiers): Observable<CloseWindowMessage> {
    return this._closeWindow$.pipe(
      filter(
        message =>
          message.windowIdentifiers.visitId === ids.visitId && message.windowIdentifiers.sessionId === ids.sessionId,
      ),
    );
  }
}
