import { ErrorHandler, Inject, Injectable, InjectionToken } from '@angular/core';
import isObject from 'lodash-es/isObject';
import Rollbar from 'rollbar';

import { environment } from '../../environments/environment';
import { gitSha } from '../../webpack-environment';

export class ErrorWithExtras extends Error {
  extras: object;

  constructor(message) {
    super(message);
  }
}

function hasExtras(error: any): error is ErrorWithExtras {
  return isObject(error) && (<any>error).extras !== undefined;
}

const IGNORED_MESSAGES = [
  /Network error:.*\/api\/graphql: 401/,
  /permission_denied at .*: Client doesn't have permission to access the desired data/,
  /Request has been terminated.*/, // usually due to adblocker
];

const rollbarConfig: Rollbar.Configuration = {
  accessToken: environment.rollbar.postClientItem,
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: environment.rollbar.enabled,
  payload: {
    environment: environment.environmentName,
    client: {
      javascript: {
        code_version: gitSha,
      },
    },
    server: {
      root: 'webpack:///./', // https://docs.rollbar.com/docs/source-control#serverroot
    },
  },
  checkIgnore: (isUncaught, args, payload): boolean => {
    const error = args[0] as Error;
    if (error.message) {
      return IGNORED_MESSAGES.some(ignoredMessage => !!error.message.match(ignoredMessage));
    }

    return !isUncaught;
  },
};

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  constructor(@Inject(RollbarService) private rollbar: Rollbar) {}

  handleError(err: any): void {
    const error = err.originalError || err;

    if (environment.rollbar.enabled) {
      const extras = hasExtras(error) ? error.extras : null;
      this.rollbar.error(error, extras);
    } else {
      console.error(error);
    }
  }
}

export function rollbarFactory() {
  return new Rollbar(rollbarConfig);
}
