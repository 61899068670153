import { add as dfnsAdd, differenceInSeconds, sub as dfnsSub } from 'date-fns/esm';

interface Duration {
  years?: number;
  months?: number;
  weeks?: number;
  days?: number;
  hours?: number;
  minutes?: number;
  seconds?: number;
}

interface TimeDifference {
  minutes: number;
  seconds: number;
}

const SECONDS_PER_MINUTE = 60;

export class DateHelpers {
  static add(date: Date, duration: Duration): Date {
    return dfnsAdd(date, duration);
  }

  static sub(date: Date, duration: Duration): Date {
    return dfnsSub(date, duration);
  }

  static absoluteDifference(left: Date, right: Date): TimeDifference {
    const seconds = Math.abs(differenceInSeconds(left, right));
    const minutes = Math.floor(seconds / SECONDS_PER_MINUTE);
    const remainingSeconds = seconds - minutes * SECONDS_PER_MINUTE;

    return {
      minutes: minutes,
      seconds: remainingSeconds,
    };
  }
}
