import { AfterViewInit, ChangeDetectorRef, Component, HostListener, Inject, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import Rollbar from 'rollbar';
import { combineLatest, noop, Subscription } from 'rxjs';
import { filter, map, retry, take } from 'rxjs/operators';

import { PatientInfoForVirtualVisitsVariables } from '@app/__generated__/PatientInfoForVirtualVisits';
import { LoggerService } from '@app/core/logger.service';
import { UnhydratedVirtualVisit, VideoCallType } from '@app/core/models/unhydrated-virtual-visit';
import {
  CallState,
  ErrorMessage,
  ShowErrorOptions,
  VideoContainer,
  VideoState,
} from '@app/core/models/video-container';
import { RealtimeDbService } from '@app/core/realtime-db.service';
import { RollbarService } from '@app/core/rollbar';
import { VideoWindowService } from '@app/core/video-window.service';
import { WindowPropertiesService } from '@app/core/window-properties.service';
import { WindowService } from '@app/core/window.service';
import { AlertType } from '@app/ui/alert/alert.component';
import { PageState } from '@app/ui/pipes/page-state.pipe';

import { CurrentUser, CurrentUser_internalUser } from './__generated__/CurrentUser';
import { PatientNameForVirtualVisit } from './__generated__/PatientNameForVirtualVisit';

export const CURRENT_USER_QUERY = gql`
  query CurrentUser {
    internalUser {
      id
    }
  }
`;

export interface Status {
  callState: CallState;
  videoState: VideoState;
}

export const GET_PATIENT_NAME_QUERY = gql`
  query PatientNameForVirtualVisit($ids: [ID!]!) {
    patients(ids: $ids) {
      profileInfo {
        preferredName
      }
    }
  }
`;

@Component({
  selector: 'om-video-container',
  templateUrl: './video-container.component.html',
  styleUrls: ['./video-container.component.scss'],
})
export class VideoContainerComponent implements AfterViewInit {
  @ViewChild('videoContainer') videoContainer: VideoContainer;

  callState = CallState.Unstarted;
  videoState = VideoState.Unstarted;
  pageState: PageState;
  errorMessage: string;
  patientPreferredName: string;
  visit: UnhydratedVirtualVisit;

  private onCallEndedSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private realtimeDbService: RealtimeDbService,
    private windowService: WindowService,
    @Inject(RollbarService) private rollbar: Rollbar,
    private videoWindowService: VideoWindowService,
    private apollo: Apollo,
    private windowPropertiesService: WindowPropertiesService,
    private logger: LoggerService,
    private cd: ChangeDetectorRef,
  ) {}

  readonly CallState = CallState;
  readonly VideoState = VideoState;
  readonly AlertType = AlertType;
  readonly VideoCallType = VideoCallType;

  ngAfterViewInit() {
    combineLatest([this.route.paramMap, this.apollo.query<CurrentUser>({ query: CURRENT_USER_QUERY })]).subscribe({
      next: ([params, result]) => {
        const visitId = params.get('visitId');
        const sessionId = params.get('sessionId');

        this.videoWindowService.getCloseWindow$({ visitId, sessionId }).subscribe(() => this.windowService.close());

        this.realtimeDbService.store
          .getCall(visitId)
          .pipe(take(1))
          .subscribe({
            next: virtualVisit => {
              if (!virtualVisit) {
                this.callState = CallState.PatientLeft;
                return;
              }

              if (this.claimedByCurrentUser(virtualVisit, result.data.internalUser)) {
                this.apollo
                  .query<PatientNameForVirtualVisit, PatientInfoForVirtualVisitsVariables>({
                    query: GET_PATIENT_NAME_QUERY,
                    variables: { ids: [virtualVisit.queuedBy] },
                  })
                  .pipe(
                    map(response => response.data.patients),
                    filter(patients => patients.length > 0),
                    map(patients => patients[0].profileInfo.preferredName),
                  )
                  .subscribe(preferredName => (this.patientPreferredName = preferredName));

                this.initVideo(virtualVisit, sessionId);
              } else {
                this.callState = CallState.ClaimedByAnother;
                this.showError({
                  error: new Error(CallState.ClaimedByAnother),
                  errorMessage: ErrorMessage.ClaimedByAnotherUser,
                  errorLogger: this.logger,
                });
              }
            },
          });
      },
    });
  }

  onActionClicked() {
    if (this.callState === CallState.Unstarted && this.videoState === VideoState.Unstarted) {
      this.startCall();
    } else {
      this.endCall();
    }
  }

  startCall() {
    this.pageState = PageState.LOADING;
    this.errorMessage = undefined;

    this.videoContainer.provider.startCall$().subscribe({
      error: (error: Error) => {
        this.showError({ error });
        this.pageState = PageState.ERROR;
        this.videoState = VideoState.StreamingError;
      },
      complete: () => {
        this.realtimeDbService.store
          .startCall(this.visit.id)
          .pipe(retry(2))
          .subscribe({
            error: noop,
            next: _ => {
              this.callState = CallState.Started;
              this.pageState = PageState.SUCCESS;
              this.videoState = VideoState.Started;
            },
          });
      },
    });
  }

  @HostListener('window:beforeunload', ['$event'])
  confirmClose($event) {
    if (this.callState === CallState.Started) {
      // Most browsers ignore this message, but let's include one for documentation purposes.
      $event.returnValue =
        'The patient has not yet finished disconnecting; are you sure you want to close the window? If possible, always use the "Close Window" button.';
    }
  }

  @HostListener('window:unload')
  saveWindowProperties(): void {
    const properties = this.windowService.getCurrentWindowProperties();
    this.windowPropertiesService.saveVideoProperties(properties);
  }

  endCall() {
    this.pageState = PageState.LOADING;
    this.errorMessage = undefined;

    if (this.callState === CallState.Started) {
      this.onCallEndedSubscription.unsubscribe();

      this.realtimeDbService.store.endCall(this.visit.id).subscribe({
        error: error => {
          this.showError({
            error,
            errorMessage: ErrorMessage.EndCallFailed,
          });
          this.pageState = PageState.ERROR;
        },
        next: _ => {
          this.pageState = PageState.SUCCESS;
          this.callState = CallState.ProviderEnded;

          this.videoContainer.provider.endCall();
          this.windowService.close();
        },
      });
    } else {
      if (this.videoState === VideoState.Started || this.videoState === VideoState.StreamingError) {
        this.videoContainer.provider.endCall();
      }
      this.windowService.close();
    }
  }

  showError(options: ShowErrorOptions): void {
    const { error } = options;
    const errorMessage = options.errorMessage ? options.errorMessage : error.message;
    const errorLogger = options.errorLogger ? options.errorLogger : this.rollbar;

    errorLogger.error(error);
    this.errorMessage = errorMessage;
  }

  private initVideo(virtualVisit: UnhydratedVirtualVisit, sessionId: string) {
    this.visit = virtualVisit;
    this.cd.detectChanges();

    this.videoContainer.init(sessionId);

    this.onCallEndedSubscription = this.realtimeDbService.store.callEnded$(this.visit.id).subscribe({
      next: _ => {
        this.callState = CallState.PatientEnded;
        this.endCall();
      },
    });
  }

  private claimedByCurrentUser(virtualVisit: UnhydratedVirtualVisit, internalUser: CurrentUser_internalUser): boolean {
    return virtualVisit.claimedBy === internalUser.id;
  }
}
