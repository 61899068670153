import { Observable } from 'rxjs';

import { ID } from '@app/core/models/id';
import { VirtualVisit } from '@app/core/models/virtual-visit';

export enum VirtualVisitState {
  Queued = 'queued',
  Claimed = 'claimed',
  InProgress = 'in_progress',
  Ended = 'ended',
  Failed = 'failed',
  Cancelled = 'cancelled',
}

export enum VirtualVisitEndedBy {
  InternalUser = 'internal_user',
  Patient = 'patient',
}

export class VirtualVisitStates {
  static readonly QueuedAndInProgressStates = [
    VirtualVisitState.Queued,
    VirtualVisitState.Claimed,
    VirtualVisitState.InProgress,
  ];

  static readonly InProgressStates = [VirtualVisitState.Claimed, VirtualVisitState.InProgress];

  static readonly All = [
    VirtualVisitState.Queued,
    VirtualVisitState.Claimed,
    VirtualVisitState.InProgress,
    VirtualVisitState.Ended,
  ];
}

export enum VideoCallType {
  Zoom = 'Zoom',
  OpenTok = 'OpenTok',
}

export interface UnhydratedVirtualVisit {
  id: string;
  visitState: VirtualVisitState;
  queuedBy: ID;
  queuedAt: Date;
  sessionId: string;
  videoCallType: VideoCallType;
  licensingBody?: string;
  claimedAt?: Date;
  claimedBy?: ID;
  reasonForVisit?: string;
  startedAt?: Date;
  endedBy?: VirtualVisitEndedBy;
  endedAt?: Date;
}

export const TIME_FIELDS = ['queuedAt', 'claimedAt', 'startedAt', 'endedAt'];

export interface VirtualVisitForUpdate<T> {
  id: string;
  visitState?: VirtualVisitState;
  claimedAt?: T;
  claimedBy?: ID | T;
  queuedBy?: ID;
  queuedAt?: T;
  licensingBody?: string;
  startedAt?: T;
  endedBy?: VirtualVisitEndedBy;
  endedAt?: T;
  zoomMeetingId?: string;
  openTokSessionId?: string;
}

export interface VirtualVisitForCreate<T> {
  visitState: VirtualVisitState;
  queuedBy: ID;
  queuedAt: T;
  licensingBody: string;
  openTokSessionId: string;
  claimedAt?: T;
  claimedBy?: ID;
  startedAt?: T;
  endedBy?: VirtualVisitEndedBy;
  endedAt?: T;
}

export interface VirtualVisitActions<T> {
  get(states: VirtualVisitState[]): Observable<UnhydratedVirtualVisit[]>;
  getCall(id: ID): Observable<UnhydratedVirtualVisit>;
  claimCall(visit: VirtualVisit, claimedBy: ID): Observable<VirtualVisit>;
  unclaimCall(id: ID): Observable<boolean>;
  startCall(id: ID): Observable<boolean>;
  unstartCall(id: ID): Observable<boolean>;
  endCall(id: ID): Observable<boolean>;
}

export interface VirtualVisitEvents {
  callEnded$(visitId: ID): Observable<void>;
}
