<div class="w-100 h-100 wrapper">
  <omgui-alert *ngIf="errorMessage" [type]="AlertType.Danger" (close)="errorMessage = undefined">
    {{ errorMessage }}
  </omgui-alert>

  <div *ngIf="visit" class="w-100 h-100">
    <om-open-tok
      #videoContainer
      *ngIf="visit.videoCallType === VideoCallType.OpenTok"
      [(callState)]="callState"
      [(pageState)]="pageState"
      [(videoState)]="videoState"
      [patientPreferredName]="patientPreferredName"
      (actionClicked)="onActionClicked()"
      (error)="showError($event)"
    ></om-open-tok>
    <om-zoom
      #videoContainer
      *ngIf="visit.videoCallType === VideoCallType.Zoom"
      [(callState)]="callState"
      [(pageState)]="pageState"
      [(videoState)]="videoState"
      [patientPreferredName]="patientPreferredName"
      (actionClicked)="onActionClicked()"
      (error)="showError($event)"
    ></om-zoom>
  </div>
</div>
