import { Component, Input } from '@angular/core';

@Component({
  selector: 'omgui-svg-x',
  template: `
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.89545 5L9.73835 2.26371C10.0872 1.92793 10.0872 1.38352 9.73835 1.04746L9.10653 0.439336C8.75767 0.103555 8.19205 0.103555 7.8429 0.439336L5 3.17562L2.1571 0.439336C1.80824 0.103555 1.24261 0.103555 0.893466 0.439336L0.261648 1.04746C-0.0872159 1.38324 -0.0872159 1.92766 0.261648 2.26371L3.10455 5L0.261648 7.73629C-0.0872159 8.07207 -0.0872159 8.61648 0.261648 8.95254L0.893466 9.56066C1.24233 9.89645 1.80824 9.89645 2.1571 9.56066L5 6.82438L7.8429 9.56066C8.19176 9.89645 8.75767 9.89645 9.10653 9.56066L9.73835 8.95254C10.0872 8.61676 10.0872 8.07234 9.73835 7.73629L6.89545 5Z"
        [attr.fill]="color"
      />
    </svg>
  `,
})
export class SvgXComponent {
  @Input() color = 'currentColor';

  constructor() {}
}
