import { Component, Input, OnChanges } from '@angular/core';

import { CallState, VideoState } from '@app/core/models/video-container';
import { Status } from '@app/video-container/video-container.component';

@Component({
  selector: 'om-status-message',
  templateUrl: './status-message.component.html',
})
export class StatusMessageComponent implements OnChanges {
  @Input() status: Status;
  @Input() patientName: string;

  message: string;

  ngOnChanges() {
    this.message = this.buildMessage();
  }

  private buildMessage(): string {
    if (this.status == null) {
      return;
    }

    if (this.status.callState === CallState.Unstarted && this.patientName) {
      return `${this.patientName} is waiting to speak to you.`;
    } else if (this.status.videoState === VideoState.PatientDropped) {
      return 'The patient dropped from the call.';
    } else if (this.status.callState === CallState.PatientLeft) {
      return 'The patient left the call.';
    }
  }
}
