export const environment = {
  environmentName: 'leevining',
  enableProdMode: true,
  devMode: true,
  topLevelDomain: '.app.1life.com',
  apiServer: 'https://leevining.app.1life.com',
  onelifeServer: 'https://leevining.app.1life.com',
  adminServer: 'https://leevining-admin.1life.com',
  chartServer: 'https://leevining-ui.app.1life.com',
  storybookRoute: '/design/index.html',
  oauth2: {
    providerUrl: 'https://leevining.app.1life.com',
    clientId: 'vVNPHVIM1txSNGgjl3ECDWya4dRcF3tcjH1fytwMwb4',
  },
  legacy_firebase: {
    apiKey: 'AIzaSyAuyz9RR-W2GgKbeK2H-AxVUm4LMjlxH8w',
    authDomain: 'legacy-one-medical-development.firebaseapp.com',
    databaseURL: 'https://legacy-one-medical-development.firebaseio.com',
    projectId: 'legacy-one-medical-development',
    storageBucket: 'legacy-one-medical-development.appspot.com',
    messagingSenderId: '735769126701',
    appId: '1:735769126701:web:7355a66ae64775a49115a4',
  },
  firebase: {
    apiKey: 'AIzaSyB2B4iyZdrow07wgvH1jGAO3JDZ0AmWIjw',
    authDomain: 'one-medical-development.firebaseapp.com',
    databaseURL: 'https://one-medical-development.firebaseio.com',
    projectId: 'one-medical-development',
    storageBucket: 'one-medical-development.appspot.com',
    messagingSenderId: '265813166121',
    appId: '1:265813166121:web:2e71076ef9ef555513f438',
  },
  openTok: {
    apiKey: '45695482',
  },
  rollbar: {
    postClientItem: 'd51ef72043a5490fb0eb449425e15327',
    environment: 'leevining',
    enabled: false,
  },
};
