<div class="d-flex justify-content-between pl-2 pr-3 py-2" [class.selected]="selected" role="navigation">
  <div>
    <ng-container *ngIf="icon">
      <ng-container *ngTemplateOutlet="icon.templateRef"></ng-container>
    </ng-container>
    <span class="ml-1">{{ title }}</span>
  </div>
  <ng-container *ngIf="end">
    <ng-container *ngTemplateOutlet="end.templateRef"></ng-container>
  </ng-container>
</div>
