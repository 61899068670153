import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule, NgZone, PLATFORM_ID } from '@angular/core';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFirestoreModule } from '@angular/fire/firestore';

import { ConfigService } from '@app/core/config.service';
import {
  LegacyAngularFireAuth,
  legacyAngularFireAuthFactory,
} from '@app/core/firebase/angular-fire-wrappers/legacy-angular-fire-auth';
import {
  LegacyAngularFireDatabase,
  legacyAngularFireDatabaseFactory,
} from '@app/core/firebase/angular-fire-wrappers/legacy-angular-fire-database';
import {
  NewAngularFireAuth,
  newAngularFireAuthFactory,
} from '@app/core/firebase/angular-fire-wrappers/new-angular-fire-auth';
import {
  NewAngularFirestore,
  newAngularFirestoreFactory,
} from '@app/core/firebase/angular-fire-wrappers/new-angular-firestore';
import { RollbarErrorHandler, rollbarFactory, RollbarService } from '@app/core/rollbar';

@NgModule({
  declarations: [],
  imports: [CommonModule, HttpClientModule, AngularFirestoreModule, AngularFireAuthModule, AngularFireDatabaseModule],
  providers: [
    {
      provide: NewAngularFireAuth,
      deps: [PLATFORM_ID, NgZone, ConfigService],
      useFactory: newAngularFireAuthFactory,
    },
    {
      provide: NewAngularFirestore,
      deps: [PLATFORM_ID, NgZone, ConfigService],
      useFactory: newAngularFirestoreFactory,
    },
    {
      provide: LegacyAngularFireAuth,
      deps: [PLATFORM_ID, NgZone, ConfigService],
      useFactory: legacyAngularFireAuthFactory,
    },
    {
      provide: LegacyAngularFireDatabase,
      deps: [PLATFORM_ID, NgZone, ConfigService],
      useFactory: legacyAngularFireDatabaseFactory,
    },
    { provide: ErrorHandler, useClass: RollbarErrorHandler },
    { provide: RollbarService, useFactory: rollbarFactory },
  ],
})
export class CoreModule {}
