import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink, HttpLinkModule } from 'apollo-angular-link-http';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { ApolloLink, from } from 'apollo-link';
import { onError } from 'apollo-link-error';

import { ClearStorageService } from '@app/core/clear-storage.service';
import { ConfigService } from '@app/core/config.service';
import { OAuthService } from '@app/core/o-auth/o-auth.service';

import { ErrorWithExtras } from './core/rollbar';

export class UnhandledGraphQLError extends ErrorWithExtras {
  constructor() {
    super('Unhandled GraphQL Link Error');
    this.name = 'UnhandledGraphQLError';
  }
}

export function createAuthLink(oAuthService: OAuthService, clearStorageService: ClearStorageService) {
  return new ApolloLink((operation, forward) => {
    const token = oAuthService.token;

    if (!token) {
      clearStorageService.clearAll();
      oAuthService.login();
    }

    const authHeader = `Bearer ${token}`;
    operation.setContext({
      headers: new HttpHeaders().set('Authorization', authHeader),
    });

    return forward(operation);
  });
}

export function createUnauthorizedLink(oAuthService: OAuthService, clearStorageService: ClearStorageService) {
  return onError(({ graphQLErrors, networkError, operation, response }) => {
    if (networkError && (<HttpErrorResponse>networkError).status === 401) {
      clearStorageService.clearAll();
      oAuthService.login();
    } else {
      const error = new UnhandledGraphQLError();

      error.extras = {
        graphQLErrors,
        networkError,
        response,
        operation,
        blankToken: oAuthService.token === '',
        invalidToken: !oAuthService.token,
      };

      throw error;
    }
  });
}

export function createApollo(
  httpLink: HttpLink,
  oAuthService: OAuthService,
  configService: ConfigService,
  clearStorageService: ClearStorageService,
) {
  const uri = `${configService.environment.apiServer}/api/graphql`;
  const http = httpLink.create({ uri });

  const authLink = createAuthLink(oAuthService, clearStorageService);
  const unauthorizedLink = createUnauthorizedLink(oAuthService, clearStorageService);

  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData: {
      __schema: {
        types: [],
      },
    },
  });

  return {
    link: from([unauthorizedLink, authLink, http]),
    cache: new InMemoryCache({ fragmentMatcher }),
  };
}

@NgModule({
  exports: [ApolloModule, HttpLinkModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, OAuthService, ConfigService, ClearStorageService],
    },
  ],
})
export class GraphQLModule {}
