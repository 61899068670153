import { Component } from '@angular/core';

@Component({
  selector: 'omgui-svg-clock',
  template: `
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 0.25C3.71875 0.25 0.25 3.71875 0.25 8C0.25 12.2812 3.71875 15.75 8 15.75C12.2812 15.75 15.75 12.2812 15.75 8C15.75 3.71875 12.2812 0.25 8 0.25ZM14.75 8C14.75 11.7188 11.7188 14.75 8 14.75C4.28125 14.75 1.25 11.75 1.25 8C1.25 4.3125 4.25 1.25 8 1.25C11.6875 1.25 14.75 4.28125 14.75 8ZM10.0938 10.7812C10.25 10.9062 10.5 10.8438 10.5938 10.6875L10.875 10.3438C11 10.1562 10.9375 9.9375 10.7812 9.8125L8.59375 8.21875V3.625C8.59375 3.4375 8.40625 3.25 8.21875 3.25H7.78125C7.5625 3.25 7.40625 3.4375 7.40625 3.625V8.625C7.40625 8.75 7.4375 8.84375 7.53125 8.9375L10.0938 10.7812Z"
        fill="black"
        fill-opacity="0.87"
      />
    </svg>
  `,
})
export class SvgClockComponent {}
