import { Injectable } from '@angular/core';
import OT from '@opentok/client';
import { Apollo } from 'apollo-angular';
import { ApolloQueryResult } from 'apollo-client';
import gql from 'graphql-tag';
import { Observable, ReplaySubject, Subject } from 'rxjs';

import { ConfigService } from '@app/core/config.service';
import { LoggerService } from '@app/core/logger.service';
import { INTERNAL_USER_DOM_ID, PATIENT_DOM_ID, VideoProvider } from '@app/core/models/video-provider';
import { OpenTokToken } from '@app/core/open-tok/__generated__/OpenTokToken';
import { WrappedError } from '@app/utils/wrapped-error';

interface OTConnectionDestroyed extends OT.Event<'connectionDestroyed', OT.Session> {
  connection: OT.Connection;
  reason: string;
}

interface OTStreamCreated extends OT.Event<'streamCreated', OT.Publisher> {
  stream: OT.Stream;
}

export const GET_TOKEN_QUERY = gql`
  query OpenTokToken($sessionId: String!) {
    openTokToken(sessionId: $sessionId)
  }
`;

class StreamSharingError extends WrappedError {
  constructor(error) {
    super('StreamSharingError', 'Failed to connect with the patient. Please try again.', error);
  }
}

class VideoConnectionFailed extends WrappedError {
  constructor(error) {
    super('VideoConnectionFailed', 'Failed to transmit your video. Please try again.', error);
  }
}

@Injectable({
  providedIn: 'root',
})
export class OpenTokService implements VideoProvider {
  private session: OT.Session;
  private subscriber: OT.Subscriber;
  private publisher: OT.Publisher;
  private subscriberStreams: OT.Stream[] = [];
  private subscribedStreamIndex: number;
  private readonly defaultSubscriberProperties = {
    width: '100%',
    height: '100%',
  };

  private _patientDropped$ = new Subject<void>();
  readonly patientDropped$ = this._patientDropped$.asObservable();

  private _initializingPublishing$ = new Subject<void>();
  readonly initializingPublishing$ = this._initializingPublishing$.asObservable();

  private _feedCount$ = new ReplaySubject<number>(1);
  readonly feedCount$ = this._feedCount$.asObservable();

  private videoStream$ = new Subject<void>();

  constructor(private apollo: Apollo, private config: ConfigService, private logger: LoggerService) {}

  init(sessionId: string): void {
    this.session = OT.initSession(this.config.environment.openTok.apiKey, sessionId);
    this.publisher = this.initializePublisher(this.handlePublisherInitResult.bind(this));

    this.session.on({
      sessionConnected: this.sessionConnectedHandler.bind(this),
      streamCreated: this.streamCreatedHandler.bind(this),
      connectionDestroyed: this.connectionDestroyedHandler.bind(this),
    });
  }

  startCall$(): Observable<void> {
    this.apollo.query({ query: GET_TOKEN_QUERY, variables: { sessionId: this.session.sessionId } }).subscribe({
      next: (response: ApolloQueryResult<OpenTokToken>) => {
        const token = response.data.openTokToken;
        this.session.connect(token, error => {
          if (error) {
            this.videoStream$.error(new VideoConnectionFailed(error));
          }
        });
      },
    });

    return this.videoStream$.asObservable();
  }

  endCall(): void {
    if (this.subscriber) {
      this.session.unsubscribe(this.subscriber);
    }

    if (this.publisher) {
      this.session.unpublish(this.publisher);
    }

    this.session.disconnect();
  }

  switchFeed(): void {
    if (this.subscriberStreams.length <= 1) {
      return;
    }

    if (this.subscribedStreamIndex === this.subscriberStreams.length - 1) {
      this.subscribedStreamIndex = 0;
    } else {
      this.subscribedStreamIndex++;
    }

    this.subscriber = this.session.subscribe(
      this.subscriberStreams[this.subscribedStreamIndex],
      PATIENT_DOM_ID,
      {
        ...this.defaultSubscriberProperties,
        insertMode: 'replace',
      },
      this.handleStreamSharingCompletion.bind(this),
    );
  }

  private handlePublisherInitResult(error) {
    if (error) {
      this._initializingPublishing$.error(error);
    } else {
      this._initializingPublishing$.complete();
    }
  }

  private initializePublisher(completionHandler: (error) => {}) {
    return OT.initPublisher(
      INTERNAL_USER_DOM_ID,
      { insertMode: 'append', width: '100%', height: '100%' },
      completionHandler,
    );
  }

  private handleStreamSharingCompletion(error) {
    if (error) {
      this.videoStream$.error(new StreamSharingError(error));
    }
  }

  private sessionConnectedHandler(sessionConnectEvent: OT.Event<'sessionConnected', OT.Session>) {
    // @ts-ignore the OT type is innaccurate here; target.connections does exist
    const connectionCount = sessionConnectEvent.target.connections.length();
    if (connectionCount >= 3) {
      this.logger.log(
        `Another provider has already connected to this call. Connection count: ${connectionCount}`,
        'OpenTokService',
      );
    }

    this.session.publish(this.publisher, this.handleStreamSharingCompletion.bind(this));
    this.videoStream$.complete();
  }

  private streamCreatedHandler(event: OTStreamCreated) {
    const options: OT.SubscriberProperties = {
      ...this.defaultSubscriberProperties,
      insertMode: 'append',
    };

    this.subscriberStreams.push(event.stream);
    if (this.subscriberStreams.length > 1) {
      options.insertMode = 'replace';
    }

    this.subscribedStreamIndex = this.subscriberStreams.length - 1;
    this._feedCount$.next(this.subscriberStreams.length);

    this.subscriber = this.session.subscribe(
      event.stream,
      PATIENT_DOM_ID,
      options,
      this.handleStreamSharingCompletion.bind(this),
    );
  }

  private connectionDestroyedHandler(event: OTConnectionDestroyed) {
    this._patientDropped$.next();
  }
}
