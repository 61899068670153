import { NgZone } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';

import { ConfigService } from '@app/core/config.service';

export class LegacyAngularFireDatabase extends AngularFireDatabase {}

export function legacyAngularFireDatabaseFactory(
  platformId: Object,
  zone: NgZone,
  config: ConfigService,
): LegacyAngularFireDatabase {
  return new LegacyAngularFireDatabase(config.environment.legacy_firebase, 'legacy_firebase', null, platformId, zone);
}
