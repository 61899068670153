import { NgZone } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

import { ConfigService } from '@app/core/config.service';

export class NewAngularFirestore extends AngularFirestore {}

export function newAngularFirestoreFactory(
  platformId: Object,
  zone: NgZone,
  config: ConfigService,
): NewAngularFirestore {
  return new NewAngularFirestore(config.environment.firebase, 'firebase', false, null, platformId, zone, null);
}
